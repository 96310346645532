<template>
    <section class="border br-t-12 mx-3 mt-2 br-t-12">
        <div class="row mx-0 align-items-center border-bottom py-2 pl-3">
            <div
            class="bg-general3 d-middle-center text-white br-50 cr-pointer"
            style="width: 18px; height: 18px"
            @click="$router.back()"
            >
                <i class="icon-left f-18" />
            </div>
            <div class="col-auto f-15 f-500 text-general">
                Confirmar Pedido
            </div>
        </div>
        <div class="row mx-0 mt-4">
            <ValidationObserver ref="form" class="col" tag="div">
                <div class="col custom-scroll overflow-auto box-delivery py-3" style="height: calc(100vh - 126px)">
                    <div v-show="!showForm">
                        <div class="row mx-0 align-items-center">
                            <p class="col-6 text-general pl-3 f-17 f-600">Seleccionar Cliente</p>
                        </div>
                        <div>
                            <div v-show="$tienda.cliente_app || todasOpciones" class="row mx-0 mt-2">
                                <div class="col-6">
                                    <div class="border text-center text-general px-3 br-8 py-1 f-15 cr-pointer" @click="openModalCliente(1)">
                                        Registrado en la aplicación
                                    </div>
                                </div>
                            </div>
                            <div v-show="$tienda.cliente_digitado || todasOpciones" class="row mx-0 mt-2">
                                <div class="col-6">
                                    <div class="border text-center text-general px-3 br-8 py-1 f-15 cr-pointer" @click="openModalCliente(0)">
                                        Registro manual
                                    </div>
                                </div>
                            </div>
                            <div v-show="$tienda.cliente_yo || todasOpciones" class="row mx-0 mt-2">
                                <div class="col-6">
                                    <div class="border text-center text-general px-3 br-8 py-1 f-15 cr-pointer" @click="openModalCliente(2)">
                                        El pedido es para mi
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-show="showForm" class="row mx-0 align-items-center">
                        <p class="col-6 text-general pl-3 f-17 f-600">Datos del Cliente</p>
                    </div>

                    <div v-show="showForm" class="row mx-0 my-3">
                        <div v-show="cliente_seleccionado.tipo === 1" class="d-middle text-general w-100">
                            <div class="col-4 my-2 d-middle">
                                <img class="border rounded-circle bg-whitesmoke" :src="cliente_seleccionado.avatar" width="40" height="40" alt="" />
                                <p class="text-general f-14 pl-3 f-500">{{ cliente_seleccionado.nombre }}</p>
                            </div>
                            <div class="col-4 my-2 d-middle">
                                <i class="icon-phone" />
                                <p class="mx-2 f-15">{{ cliente_seleccionado.telefono || 'Sin Telefono' }}</p>
                            </div>
                            <div class="col-auto" @click="remover_cliente">
                                <p class="mx-2 f-14 text-general-red border-b-red cr-pointer">Cambiar Cliente</p>
                            </div>
                        </div>

                        <div v-show="cliente_seleccionado.tipo === 2" class="d-middle text-general w-100">
                            <div class="col-4 my-2 d-middle">
                                <img class="border rounded-circle bg-whitesmoke" :src="$tienda.logo" width="40" height="40" alt="" />
                                <p class="text-general f-14 pl-3 f-500">Yo, como consumidor</p>
                            </div>
                            <div class="col-auto" @click="remover_cliente">
                                <p class="mx-2 f-14 text-general-red border-b-red cr-pointer">Cambiar Cliente</p>
                            </div>
                        </div>

                        <!-- REGISTRO MANUAL -->
                        <div v-show="cliente_seleccionado.tipo === 0" class="row mx-0">
                            <div class="col-8 my-2">
                                <p class="text-general f-14 pl-3">Nombre del cliente</p>
                                <p class="border br-10 pl-3 p-2 bg-whitesmoke text-general f-14">{{ cliente_seleccionado.nombre }}</p>
                            </div>
                            <div class="col-4 pt-4">
                                <div class="col-auto" @click="remover_cliente">
                                    <p class="mx-2 f-14 text-general-red border-b-red cr-pointer">Cambiar Cliente</p>
                                </div>
                            </div>
                            <div class="col-10 my-2">
                                <p class="text-general f-14 pl-3">Número de telefono</p>
                                <ValidationProvider
                                v-slot="{ errors }"
                                class="w-100"
                                :rules="{ required: cliente_seleccionado.tipo === 0 }"
                                name="telefono"
                                >
                                    <el-input
                                    v-model="cliente_seleccionado.telefono"
                                    class="w-100"
                                    placeholder="Número de telefono"
                                    maxlength="14"
                                    show-word-limit
                                    :disabled="Boolean(cliente_seleccionado.tipo)"
                                    />
                                    <p class="text-danger w-100 f-11 pt-1 pl-3">{{ errors[0] }}</p>
                                </ValidationProvider>
                            </div>
                        </div>

                        <!-- CLIENTE REGISTRADO EN AL APP -->
                        <div v-show="cliente_seleccionado.tipo === 1" class="col-10 my-2">
                            <p class="text-general f-14 pl-3">Dirección del cliente</p>
                            <ValidationProvider
                            v-slot="{ errors }"
                            class="w-100"
                            :rules="{ required: cliente_seleccionado.tipo === 1 }"
                            name="dirección"
                            >
                                <div class="d-middle">
                                    <i v-show="!sinCoberturaCliente" class="icon-map-marker-outline text-general f-22" />
                                    <el-tooltip v-show="sinCoberturaCliente" content="No existe cobertura en esta area" placement="bottom" effect="light">
                                        <i class="icon-eye-off bg-warning text-white f-22 rounded-circle px-1 mr-2" />
                                    </el-tooltip>
                                    <el-select
                                    v-model="cliente_seleccionado.direccion_seleccionada"
                                    placeholder="Seleccionar dirección"
                                    class="w-100"
                                    @change="set_direccion"
                                    >
                                        <el-option
                                        v-for="(item, index3) in cliente_seleccionado.direcciones"
                                        :key="index3"
                                        :label="item.direccion"
                                        :value="item.id"
                                        />
                                    </el-select>
                                </div>
                                <p class="text-danger w-100 f-11">{{ errors[0] }}</p>
                            </ValidationProvider>
                        </div>

                        <div v-if="cliente_seleccionado.tipo === 2 || cliente_seleccionado.tipo === 0" class="col-10 align-items-end my-2">
                            <p class="text-general f-14 pl-3 w-100 f-500">Dirección de entrega</p>
                            <div class="d-flex mr-2 cr-pointer" @click="anadirDirecciones()">
                                <i v-show="!sinCoberturaTendero" class="icon-map-marker-outline text-general f-22" />
                                <el-tooltip v-show="sinCoberturaTendero" content="No existe cobertura en esta area" placement="bottom" effect="light">
                                    <i class="icon-eye-off bg-warning text-white f-22 rounded-circle px-1 mr-2" />
                                </el-tooltip>
                                <div class="border br-6 px-2 d-middle-center cr-pointer tres-puntos" style="height:32px;max-width:343px;">
                                    <span class="f-14 text-general tres-puntos">{{ _.get(direccionLechero, 'direccion', 'No registra') }}</span>
                                    <i class="icon-angle-down text-gris ml-2" />
                                </div>
                            </div>
                        </div>

                        <div v-show="cliente_seleccionado.tipo === 1" class="col-10">
                            <p class="text-general f-600 f-17">
                                ¿Quién suministrará los productos al cliente?
                            </p>
                            <div class="row mx-0 mt-2">
                                <div class="col-auto d-middle">
                                    <input
                                    v-model="model.pedido_directo"
                                    :value="0"
                                    type="radio"
                                    class="option-input black radio"
                                    :disabled="permisoCambio"
                                    @change="cambioMinimo(0)"
                                    />
                                    Yo
                                </div>
                                <div class="col-auto d-middle">
                                    <input
                                    v-model="model.pedido_directo"
                                    :value="1"
                                    type="radio"
                                    class="option-input black radio"
                                    :disabled="permisoCambio"
                                    @change="cambioMinimo(1)"
                                    />
                                    El Centro de Distribución
                                </div>
                            </div>
                        </div>
                        <div v-show="cliente_seleccionado.tipo === 1 && model.pedido_directo" class="col-10 mt-3">
                            <p class="text-general f-600 f-17">¿Quién realiza la entrega?</p>
                            <div class="row mx-0 mt-2">
                                <div class="col-auto d-middle">
                                    <input
                                    v-model="model.entrega_directa"
                                    :value="0"
                                    type="radio"
                                    class="option-input black radio"
                                    :disabled="permisoCambio"
                                    />
                                    Yo
                                </div>
                                <div class="col-auto d-middle">
                                    <input
                                    v-model="model.entrega_directa"
                                    :value="1"
                                    type="radio"
                                    class="option-input black radio"
                                    :disabled="permisoCambio"
                                    />
                                    El Centro de Distribución
                                </div>
                            </div>
                        </div>
                        <div v-if="model.entrega_directa === 0 && model.pedido_directo ===1 && cliente_seleccionado.tipo == 1" class="col-10 mt-3 align-items-end my-2">
                            <p class="text-general f-14 pl-3 w-100 f-500">Dirección de entrega</p>
                            <div class="d-flex mr-2 cr-pointer" @click="anadirDirecciones()">
                                <i v-show="!sinCoberturaTendero" class="icon-map-marker-outline text-general f-22" />
                                <el-tooltip v-show="sinCoberturaTendero" content="No existe cobertura en esta area" placement="bottom" effect="light">
                                    <i class="icon-eye-off bg-warning text-white f-22 rounded-circle px-1 mr-2" />
                                </el-tooltip>
                                <div class="border br-6 px-2 d-middle-center cr-pointer tres-puntos" style="height:32px;max-width:343px;">
                                    <span class="f-14 text-general tres-puntos">{{ _.get(direccionLechero, 'direccion', 'No registra') }}</span>
                                    <i class="icon-angle-down text-gris ml-2" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-show="showForm">
                        <div class="row mx-0 my-2">
                            <div class="col-10">
                                <p class="f-14 text-general pl-3">Instrucciones de entrega</p>
                                <el-input
                                v-model="model.instrucciones"
                                type="textarea"
                                show-word-limit
                                :maxlength="500"
                                :rows="4"
                                class="w-100"
                                placeholder="Menciona tus instrucciones de entrega"
                                />
                            </div>
                        </div>
                        <p class="text-general pl-3 f-17 mt-4 mb-2">{{ parametros.horarios_texto }}</p>
                        <template v-if="parametros.horarios_uso === 1">
                            <p class="text-general pl-3 f-17 mt-3 f-600">Elige el día y hora de entrega</p>
                            <div class="p-3">
                                <div class="row mx-0">
                                    <div class="col-xl col-lg col-md col-sm-12 col-12 px-2">
                                        <div class="row mx-0">
                                            <div v-for="(d, key) in arrayDias"
                                                 :key="key"
                                                 class="box-shedule d-flex flex-column justify-content-center cr-pointer px-2 mr-2 mb-3"
                                                 :class="diaActivo == key ? 'bg-green text-white' : 'border text-general2'"
                                                 @click="setFechaEntrega(d,key)"
                                            >
                                                <p class="f-14 f-500 tres-puntos">{{ key }}</p>
                                                <p class="f-12">{{ d.fecha | helper-fecha('MMM D') }}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl col-lg col-md col-sm-12 col-12 px-2">
                                        <div v-for="(h,index) in horarios" :key="index"
                                             :class="`row mx-0 br-8 px-2 ${h.disabled ? 'disabled-div' : ''} row-hours${entregaHorario == h.value ? '-active' : ''} mb-2 cr-pointer`"
                                             @click="entregaHorario = h.value"
                                        >
                                            <div :class="`check-mn${entregaHorario == h.value ? '-active' : ''}`">
                                                <div class="inside" />
                                            </div>
                                            <div class="col px-2 tres-puntos">
                                                <span class="f-500 f-15">{{ h.inicio }}</span> a <span class="f-500">{{ h.fin }}</span>
                                            </div>
                                        </div>
                                        <div v-show="horarios.length == 0" class="border br-12 py-2">
                                            <p class="text-general2 f-15 f-600 text-center">
                                                Sin entregas para este día
                                            </p>
                                            <p class="text-general2 text-center f-14">
                                                Por favor selecciona otro.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <div class="row mx-0 mt-2">
                            <p class="f-14 text-general pl-3 f-600 w-100">Forma de pago</p>
                            <div v-for="(m,key) in metodosPagos" :key="key" class="col-auto my-1">
                                <el-radio v-model="formaPago" :label="m.nombre" :disabled="m.nombre === 'Credito' && !creditoHabilitado" @change="setMetodoPago(m)" />
                            </div>
                            <div class="row mx-0 mt-1">
                                <div class="col-auto text-general">
                                    Deuda Actual: <strong class="text-danger">
                                        {{ convertMoneyTendero(deudaActual) }}
                                    </strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ValidationObserver>
            <div class="col custom-scroll overflow-auto">
                <p class="text-general f-17 f-600">Resumen del pago</p>
                <div class="row mx-0 text-general align-items-center my-2">
                    <div class="col-8">
                        <div class="row mx-0 my-2">
                            <div class="col pl-0">
                                Costo de productos ({{ resumen.cant_productos }})
                            </div>
                            <div class="col-auto pr-2 f-600">
                                {{ convertMoneyTendero( resumen.costo_productos, $tienda.idm_moneda ) }}
                            </div>
                        </div>
                        <div class="row mx-0 my-2">
                            <div class="col pl-0">
                                Descuento
                            </div>
                            <div class="col-auto pr-2 f-600 text-general-red">
                                {{ convertMoneyTendero(resumen.descuento, $tienda.idm_moneda) }}
                            </div>
                        </div>
                        <div v-show="(cliente_seleccionado.tipo === 0 || cliente_seleccionado.tipo === 2) && showForm" class="row mx-0 my-3 align-items-center">
                            <i class="icon-cupon text-general-red f-20" />
                            <div
                            class="text-general-red ml-2 br-8 cr-pointer"
                            @click="openModalCuponesTendero"
                            >
                                Seleccionar Cupón
                            </div>
                        </div>
                        <div v-if="resumen.id_cupon" class="row mx-0 pr-2 pl-0 justify-content-between">
                            <div class="d-middle">
                                <i class="icon-cupon pr-1" />
                                <p>{{ resumen.codigo_cupon }}</p>
                                <i class="icon-swap-vertical-bold-down pl-2 text-aqua f-18 cr-pointer" @click="openModalCuponesTendero()" />
                            </div>
                            <b class="text-general-red">
                                {{ convertMoneyTendero(resumen.val_cupon, $tienda.idm_moneda) }}
                            </b>
                        </div>
                        <div class="row mx-0 my-2">
                            <div class="col pl-0">
                                Alianza {{ $config.vendedor }}
                            </div>
                            <div class="col-auto pr-2 f-600 text-general-red">
                                {{ convertMoneyTendero(resumen.val_condicion, $tienda.idm_moneda) }}
                            </div>
                        </div>
                        <div class="row mx-0 border-top pt-2">
                            <div class="col pl-0 f-600 text-general">
                                Sub Total
                            </div>
                            <div class="col-auto pr-2 f-600 text-general">
                                {{ convertMoneyTendero(resumen.subtotal, $tienda.idm_moneda) }}
                            </div>
                        </div>
                        <div class="row mx-0 my-2">
                            <div class="col pl-0">
                                Costo de impuestos
                            </div>
                            <div class="col-auto pr-2 f-600">
                                {{ convertMoneyTendero( resumen.val_impuestos, $tienda.idm_moneda ) }}
                            </div>
                        </div>

                        <div class="row mx-0 my-2">
                            <div class="col pl-0">
                                Costo de envio
                            </div>
                            <div class="col-auto pr-2 f-600">
                                $0
                            </div>
                        </div>
                        <div class="row mx-0 border-top pt-2">
                            <div class="col pl-0 f-600 text-general">
                                TOTAL
                            </div>
                            <div class="col-auto pr-2 f-600 text-general">
                                {{ convertMoneyTendero(resumen.total, $tienda.idm_moneda) }}
                            </div>
                        </div>
                        <div v-show="resumen.ganancia > 0" class="row mx-0 my-2 ticket br-8 py-1">
                            <div class="col pl-1 px-0 text-general f-15">
                                Tus ganancias
                            </div>
                            <div class="col-auto ml-auto total-value">
                                {{ convertMoneyTendero(resumen.ganancia, $tienda.idm_moneda) }}
                            </div>
                        </div>
                    </div>
                </div>
                <div v-show="ancho < 100" class="row mx-0 mt-3">
                    <div class="col-8 border br-8 pt-3">
                        <div class="row mx-0 bg-light-f5 br-10" style="height:8px;">
                            <div class="bg-general br-10" :style="`width:${ancho}%;`" />
                        </div>
                        <p class="f-14 text-general2 mt-2">
                            Faltan {{ faltante }} para completar el pedido minimo
                        </p>
                    </div>
                </div>

                <div class="row mx-0 mt-3">
                    <div class="col-auto">
                        <div
                        class="border text-general px-3 br-8 py-1 f-15 cr-pointer"
                        @click="$router.back()"
                        >
                            Regresar al carrito
                        </div>
                    </div>
                    <div class="col-auto">
                        <div
                        class="bg-general text-white br-8 px-3 py-1 f-15 shadow cr-pointer"
                        @click="finalizar_pedido"
                        >
                            Finalizar Pedido
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <modal ref="modalDirecciones" titulo="Direcciones" icon="ubicación" no-aceptar no-cancelar adicional="Añadir nueva dirección" @adicional="anadirNuevaDireccion">
            <div class="row mx-0 justify-center mb-3">
                <div v-for="(direccion, i) in direcciones" :key="i" class="col-12 d-flex mb-2">
                    <i :class="`icon-map-marker-outline ${direccion.principal ? 'text-success' : 'text-gris cr-pointer'}  f-25`" @click="setDireccionLechero(direccion)" />
                    <div class="col pl-2">
                        <p :class="`nombre ${direccion.principal ? 'text-general' : 'text-general2'}  f-15 f-600 `">{{ direccion.direccion }} </p>
                        <p :class="`${direccion.principal ? 'text-general' : 'text-general2'}  f-14 mt-1`">{{ direccion.nombre }}</p>
                    </div>
                    <div class="d-middle">
                        <el-tooltip v-show="!direccion.cobertura_cedis" content="No existe cobertura en esta area" placement="bottom" effect="light">
                            <i class="icon-eye-off  text-white f-22  mr-2 bg-warning rounded-circle px-1" />
                        </el-tooltip>
                        <template v-if="direccion.editando">
                            <i class="icon-pencil-outline text-general f-20 cr-pointer" @click="editarDireccionLechero(direccion)" />
                            <i class="icon-trash-empty text-general f-20 cr-pointer" @click="modalEliminarDireccionLechero(direccion)" />
                        </template>
                        <i v-else class="icon-dots-vertical text-general f-20 cr-pointer" @click="direccion.editando = !direccion.editando;" />
                    </div>
                </div>
            </div>
        </modal>
        <modal-eliminar
        ref="eliminarDireccion"
        titulo="Eliminar direccion"
        mensaje="¿Desea eliminar la dirección?"
        @eliminar="eliminarDireccion()"
        />

        <!-- Partials -->
        <modal-advertencia ref="modalAdvertencia" :tipo="tipo_respuesta" />
        <modal-pedido-minimo ref="modalPedidoMinimo" />
        <modal-seleccionar-cliente ref="modalCliente" @selected="seleccionar_cliente" />
        <modal-cupon ref="modalCupones" @update="obtener_resumen" @no-usar="remover_cupon" />
        <modal-productos-agotados ref="modalProductosAgotados" @update="obtener_resumen" />
        <modal-crear-direccion ref="modalCrearDireccion" @actualizar="fetchDirecciones" />
    </section>
</template>

<script>
import { mapGetters } from "vuex";
import PedidosNew from "~/services/pedidos/pedidos-tendero";
import PedidosTienda from "~/services/pedidos/pedidos-tienda";
import PedidosAdmin from "~/services/pedidos/pedidos-admin";
import Direcciones from '~/services/tiendas/direcciones'
export default {
    components: {
        modalAdvertencia: () => import("../partials/modalAdvertencia"),
        modalPedidoMinimo: () => import("../partials/modalPedidoMinimo"),
        modalSeleccionarCliente: () => import("../partials/modalSeleccionarCliente"),
        modalCupon: () => import("../partials/modalCupones"),
        modalProductosAgotados: () => import("../partials/modalProductosAgotados"),
        modalCrearDireccion: () => import('~/pages/auth/partials/modalCrearDirecciones')
    },
    sockets: {
        activar_chat(value){
            const { tienda } = JSON.parse(value);
            if (this.$usuario.tienda.id == tienda.id){
                this.$store.dispatch("auth/consultarUsuario");
            }
        },
    },
    data(){
        return {
            tipoCliente:null,
            ancho:0,
            faltante:0,

            cliente_seleccionado: {
                tipo: 0,
                id: null,
                avatar:null,
                nombre: "",
                telefono: "",
                direccion_seleccionada: null,
                direcciones: [],
            },
            lechero:{
                direccion_seleccionada: null,
                direcciones: [],
            },

            model: {
                pedido_directo: 0,
                entrega_directa: 0,
                entrega_fecha: "",
                instrucciones: "",
                entrega_horario: "",
                direccion_cliente: "",
                longitud_cliente: 0,
                latitud_cliente: 0,
                distancia_cliente: 0,
                domicilio_cliente: 0,

                direccion_tendero: "",
                longitud_tendero: 0,
                latitud_tendero: 0,
                distancia_tendero: 0,
                forma_pago:1,
                id_metodo_pago: null
            },
            value: null,
            showForm: false,

            tipo_respuesta: 0,

            sinCoberturaCliente:false,
            metodosPagos:[],
            formaPago:1,
            creditoHabilitado:true,
            deudaActual:0,
            arrayDias:[],
            horarios:[],
            diaActivo:'',
            entregaFecha:null,
            entregaHorario:null
        };
    },
    computed: {
        ...mapGetters({
            resumen: "pedidos/pedidos_tendero/resumen",
            direccionLechero: 'tiendas/direcciones/principal',
            direcciones: 'tiendas/direcciones/direcciones',
            parametros: "pedidos/pedidos_tendero/parametros",
        }),
        permisoCambio(){
            if(this.$tienda.cambio_tipo_pedido){
                return false
            }
            return true
        },
        todasOpciones(){
            if(this.$tienda.cliente_app && this.$tienda.cliente_digitado && this.$tienda.cliente_yo){
                return true;
            }
            if(!this.$tienda.cliente_app && !this.$tienda.cliente_digitado && !this.$tienda.cliente_yo){
                return true;
            }
            return false
        },
        sinCoberturaTendero(){
            if(!_.isEmpty(this.direccionLechero)){

                return !this.direccionLechero.cobertura_cedis
            }
            return false
        }
    },
    async mounted(){
        await this.remover_cupon()
        this.obtener_resumen();
        this.setCaso()
        this.getFormaPago()
    },
    methods: {
        setCaso(){
            if(this.todasOpciones) return

            if(this.$tienda.cliente_app && this.$tienda.cliente_digitado) return

            if(this.$tienda.cliente_app && this.$tienda.cliente_yo) return

            if(this.$tienda.cliente_digitado && this.$tienda.cliente_yo) return

            if(this.$tienda.cliente_app){
                this.openModalCliente(1)
                return
            }
            if(this.$tienda.cliente_digitado){
                this.openModalCliente(0)
                return
            }
            if(this.$tienda.cliente_yo){
                this.openModalCliente(2)
                return
            }

        },
        async openModalCliente(caso){
            this.remover_cupon()
            this.tipoCliente = caso

            if(caso === 2){
                this.cliente_seleccionado.nombre = this.$tienda.propietario_nombre;
                this.cliente_seleccionado.telefono = this.$tienda.telefono;
                this.cliente_seleccionado.tipo = 2;
                this.cliente_seleccionado.id = null;

                this.set_franja();
                this.showForm = true
                return
            }


            this.$refs.modalCliente.toggle(caso)
        },
        async finalizar_pedido(){
            try {
                const valid = await this.$refs.form.validate();
                if (!valid) return;

                if(parseInt(this.parametros.horarios_uso) === 1){
                    if(this.entregaFecha == null || this.entregaFecha == '' || this.entregaHorario == null || this.entregaHorario == ''){
                        this.notificacion('Advertencia','Por favor seleccione un fecha y un horario','warning')
                        return
                    }
                }

                let model = {

                    id_cliente: this.cliente_seleccionado.id,
                    nombre: this.cliente_seleccionado.nombre,
                    telefono: this.cliente_seleccionado.telefono,

                    domicilio: this.model.domicilio_cliente,

                    entrega_fecha: this.entregaFecha,
                    entrega_horario: this.entregaHorario,
                    instrucciones: this.model.instrucciones,
                    pedido_directo: this.model.pedido_directo,
                    entrega_directa: this.model.entrega_directa,
                    forma_pago: this.model.forma_pago,
                    id_metodo_pago: this.model.id_metodo_pago,
                };

                if(this.cliente_seleccionado.tipo === 0 || this.cliente_seleccionado.tipo === 2){
                    this.set_direccion_lechero()
                    model.direccion_tendero = this.model.direccion_tendero
                    model.distancia_tendero = this.model.distancia_tendero
                    model.longitud_tendero = this.model.longitud_tendero
                    model.latitud_tendero = this.model.latitud_tendero
                    model.tipo = 0
                }
                if(this.cliente_seleccionado.tipo === 1){
                    model.direccion_cliente = this.model.direccion_cliente
                    model.distancia_cliente = this.model.distancia_cliente
                    model.longitud_cliente = this.model.longitud_cliente
                    model.latitud_cliente = this.model.latitud_cliente
                    model.tipo = 1
                }

                if(this.model.entrega_directa === 0){
                    this.set_direccion_lechero()
                    model.direccion_tendero = this.model.direccion_tendero
                    model.distancia_tendero = this.model.distancia_tendero
                    model.longitud_tendero = this.model.longitud_tendero
                    model.latitud_tendero = this.model.latitud_tendero
                }

                console.log(model);
                const { data } = await PedidosNew.agregar_pedido(model);

                if (data.code == 1){
                    this.tipo_respuesta = 1; // enviar a registro
                    this.$refs.modalAdvertencia.toggle();
                    return;
                }
                if (data.code == 2){
                    this.tipo_respuesta = 2; // enviar a asesor
                    this.$refs.modalAdvertencia.toggle();
                    return;
                }
                if (data.code == 5){ // Productos agotados
                    this.$refs.modalProductosAgotados.toggle(data.productos);
                    return;
                }

                if (data.code == 4){ // No cumple con el pedido Minimo
                    this.$refs.modalPedidoMinimo.toggle(data);
                    return;
                }
                if (data.code == 6){ // No se tiene cobertura en el area
                    this.tipo_respuesta = 3;
                    this.$refs.modalAdvertencia.toggle();
                    return;
                }

                if (data.code == 3){
                    this.$router.push({
                        name: "tendero.carrito.pedido-creado",
                        query: {
                            id_pedido: data.data.id_pedido,
                            id_admin_pedido: data.data.id_admin_pedido,
                        },
                    });
                    return;
                }
            } catch (e){
                this.error_catch(e);
            }
        },
        async obtener_resumen(){
            await this.$store.dispatch("pedidos/pedidos_tendero/get_resumen");
            this.ancho = (this.resumen.total / this.resumen.pedido_minimo) * 100
            this.faltante = this.convertMoneyTendero(this.resumen.pedido_minimo - this.resumen.total,this.$tienda.idm_moneda)

        },
        async remover_cupon(){
            try {
                const {data} = await PedidosNew.remover_cupon();
                this.obtener_resumen();

            } catch (e){
                this.error_catch(e);
            }
        },
        async seleccionar_cliente(cliente){
            if (cliente.tipo === 1){
                this.cliente_seleccionado.tipo = 1;
                this.cliente_seleccionado.avatar = cliente.avatar;
                this.cliente_seleccionado.id = cliente.id_user;
                this.cliente_seleccionado.nombre = cliente.nombre;
                this.cliente_seleccionado.telefono = cliente.telefono;
                this.cliente_seleccionado.direccion_seleccionada = null;
                this.cliente_seleccionado.direcciones = cliente.direcciones;

                this.model.pedido_directo = this.$tienda.cliente_pedido_directo;
                this.model.entrega_directa = this.$tienda.cliente_entrega_directa;
                this.set_franja();
            } else {
                this.cliente_seleccionado.nombre = cliente.nombre;
                this.cliente_seleccionado.telefono = cliente.telefono;
                this.cliente_seleccionado.tipo = 0;
                this.cliente_seleccionado.id = null;
                this.cliente_seleccionado.direccion_seleccionada = null;
                this.cliente_seleccionado.direcciones = [];


                this.set_franja();
            }
            this.showForm = true
        },
        remover_cliente(){
            this.tipoCliente = null;
            this.showForm=false
            this.cliente_seleccionado = {
                tipo: 0,
                avatar:null,
                nombre: "",
                telefono: "",
                direccion_seleccionada: null,
                direcciones: [],
            };
            this.model.entrega_fecha = ""
            this.model.entrega_horario = ""
        },
        async set_franja(){
            try {
                const tipo = this.cliente_seleccionado.tipo
                let response = {};
                if (tipo === 1 && this.model.pedido_directo){
                    let id_cedis = this.$tienda.id_cedis;
                    if (id_cedis === null){
                        this.notificacion( "Advertencia", "La tienda no tiene un CEDIS asignado.", "warning" );
                        return;
                    }

                    response = await PedidosAdmin.get_horarios(id_cedis);
                }

                if(tipo === 1 && this.model.pedido_directo == 0){
                    response = await PedidosTienda.get_horarios();
                }

                if (tipo === 0 || tipo === 2){
                    let id_cedis = this.$tienda.id_cedis;
                    if (id_cedis === null){
                        this.notificacion( "Advertencia", "La tienda no tiene un CEDIS asignado.", "warning" );
                        return;
                    }

                    response = await PedidosAdmin.get_horarios(id_cedis);
                }

                const { data } = response;
                this.arrayDias = data.franjas

            } catch (e){
                this.error_catch(e);
            }
        },
        set_direccion(id){
            let direccion = this.cliente_seleccionado.direcciones.find( (o) => o.id === id );
            this.model.direccion_cliente = direccion.direccion;
            this.model.longitud_cliente = direccion.longitud;
            this.model.latitud_cliente = direccion.latitud;
            this.model.domicilio_cliente = direccion.valor_domicilio;

            if(this.model.entrega_directa){
                this.model.distancia_cliente = direccion.distancia_cedis;
                this.sinCoberturaCliente = !direccion.cobertura_cedis
            }
            if(this.model.entrega_directa === 0){
                this.model.distancia_cliente = direccion.distancia_tienda;
                this.sinCoberturaCliente = !direccion.cobertura_tienda
            }
        },
        set_direccion_lechero(){
            let direccion = this.direccionLechero
            this.model.direccion_tendero = direccion.direccion_completa;
            this.model.longitud_tendero = direccion.longitud;
            this.model.latitud_tendero = direccion.latitud;
            this.model.distancia_tendero = direccion.distancia;
        },
        cambioMinimo(tipo){
            if(tipo === 1){
                this.ancho = (this.resumen.total / this.resumen.pedido_minimo) * 100
                this.faltante = this.convertMoneyTendero(this.resumen.pedido_minimo - this.resumen.total,this.$tienda.idm_moneda)
            }else{
                this.ancho = (this.resumen.total / this.resumen.pedido_minimo_tienda) * 100
                this.faltante = this.convertMoneyTendero(this.resumen.pedido_minimo_tienda - this.resumen.total,this.$tienda.idm_moneda)
            }
            this.cleanFechaEntrega()
        },
        openModalCuponesTendero(){
            this.$refs.modalCupones.toggle()
        },
        async selectDirecciones(){
            try {
                const {data} = await PedidosAdmin.selectDirecciones(this.$tienda.id)
                this.lechero.direcciones = data.direcciones
                //this.lechero.direccion_seleccionada = data.actual;

            } catch (e){
                this.error_catch(e)
            }
        },
        async anadirDirecciones(){
            await this.fetchDirecciones()
            this.$refs.modalDirecciones.toggle();
        },
        editarDireccionLechero(direccion){
            this.$refs.modalDirecciones.toggle();
            this.$refs.modalCrearDireccion.editar(direccion);
        },
        modalEliminarDireccionLechero({id}){
            if(this.direcciones.length == 1) return this.notificacion('Advertencia', 'No se puede eliminar esta dirección, es la única existente', 'warning')

            this.id = id
            this.$refs.modalDirecciones.toggle();
            this.$refs.eliminarDireccion.toggle();

        },
        anadirNuevaDireccion(){
            this.$refs.modalDirecciones.toggle();
            this.$refs.modalCrearDireccion.toggle();
        },
        async fetchDirecciones(){
            await this.$store.dispatch('tiendas/direcciones/fetchDirecciones')
        },
        async setDireccionLechero(direccion){
            try {
                if(direccion.principal) return
                const { data } = await Direcciones.direccionPrincipal(direccion.id)
                this.fetchDirecciones()

            } catch (error){
                this.error_catch(error)
            }
        },
        cleanFechaEntrega(){
            this.horarios = []
            this.diaActivo = '',
            this.entregaFecha = null
            this.entregaHorario = null
            this.set_franja()
        },
        async getFormaPago(){
            try {
                const {data} = await PedidosNew.getFormaPago();
                this.creditoHabilitado = data.credito
                this.metodosPagos = data.metodos_pago,
                this.deudaActual = data.deuda

            } catch (e){
                this.error_catch(e);
            }
        },
        setMetodoPago(item){
            this.model.forma_pago = item.tipo
            this.model.id_metodo_pago = item.id_metodo_pago
        },
        setFechaEntrega(item,nombreDia){
            this.horarios = item.horarios
            this.diaActivo = nombreDia
            this.entregaFecha = item.fecha
            this.entregaHorario = null
        },
    },
};
</script>
<style lang="scss" scoped>
.br-t-12 {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.ticket {
  border: 1px solid #28d07b;
  background-color: #28d07b33;
  .total-value {
    color: #28d07b;
    font-weight: 600;
  }
}
.box-delivery{
    box-shadow: 0px 3px 6px #0000000D;
    background-color: #FFFFFF;
    border-radius: 8px;
    .card-day{
        width: 60px;
        height: 49px;
        color: #707070;
        border-radius: 12px;
        border: 1px solid #DBDBDB;
        &-actual{
            @extend .card-day;
            background-color: #28D07B;
        }
    }
    .box-shedule{
    width: 60px;
    height: 49px;
    border-radius: 12px;
    &-alternative{
        width: 94px;
        height: 49px;
        border-radius: 12px;
        }
    }
}
.bg-green{
    background-color: #28D07B;
}

.row-hours{
        height: 34px;
        align-items: center;
        color: #637381;
        &-active{
            @extend .row-hours;
            border: 1px solid #29D884;
            color: #000000;
        }
        .check-mn{
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: 1px solid #637381;
            position: relative;
            .inside{
                display: none;
                position: absolute;
                left: 18%;
                top: 17%;
                background-color: #29D884;
                width: 12px;
                height: 12px;
                border-radius: 50%;
            }
            &-active{
                @extend .check-mn;
                border: 1px solid #29D884;
                .inside{
                    display: block;
                }
            }
        }
    }
</style>
